.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #e0e0e0;
}

.logoMain {
  height: 25px; /* Adjust the height as needed */
  padding-left: 4%;
}

.main-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 2000px;
}

.text-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 25px;
  line-height: 50px;
  padding-left: 5%;
  margin-top:30px
}

.text-section p {
  max-width: 800px;
  font-size: 25px;
}
.text-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: -200px;
}
.video-section {
  margin-top: 20px;
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: #000;
}

.video-placeholder {
  width: 90%;
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.pagination-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin: 0 10px;
}

.toggle-listening {
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  border: none;
  border-radius: 25px;
  color: #fff;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  background-color: #A3C4BC; /* Initial background for "Speak" */
  transition: background-color 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
  z-index: 100;
}

.toggle-listening.listening {
  background-color: #D58389; /* Background for "Stop" */
}

.toggle-listening .icon {
  margin-right: 15px;
  transition: transform 0.3s ease;
}

.toggle-listening img {
  margin-top: 10px;
  width: 24px;
  height: 24px;
}

.toggle-listening:active {
  opacity: 0.7;
}

.toggle-listening.smaller{
  display: none;
}
@media (max-width: 1100px) {
  .text-container{
    margin-left: 0px;
  }
}
@media (max-width: 768px) {
  .video-section {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: #fff;
  }
  .toggle-listening.smaller{
    display:flex;
  }
  .toggle-listening.bigger{
    display:none;
  }

  .video-placeholder {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  .container {
    padding: 20px 0;
  }

  .text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    line-height: 50px;
    padding-left: 5%;
    margin-top:30px
  }
  
  .text-section p {
    max-width: 800px;
    font-size: 20px;
  }
  .text-container{
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-left: 0px;
  }
  .toggle-listening {
    margin-top: 20px;
    margin-right: 0 ;
    width: 110px;
    height: 33px;
    font-size: 15px;
    padding: 5px 0;
  }
}